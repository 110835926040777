<template>
  <div class="form-div">
     <div><Checkbox v-model="isCheck" @on-change="changeCheck">健康需求评估</Checkbox></div>
     <Form v-show="isCheck" class="isForm" label-position="right" :label-width="120">
       <FormItem class="isNoWidth">
              <span slot="label">身体状况:</span>
              <Select clearable v-model="formDataHealth.healthStatus" >
                <Option
                v-for="item in healthStatusType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
        <FormItem class="isNoWidth" v-if="formDataHealth.healthStatus == 3">
             <span slot="label" class="validate">疾病状况:</span>
              <Input type="textarea" placeholder="请输入您的疾病状况" v-model.trim="formDataHealth.diseaseDesc"  />
        </FormItem>
       <FormItem class="isNoWidth">
             <span slot="label">自理能力:</span>
              <Select clearable v-model="formDataHealth.selfCare" >
                <Option
                v-for="item in selfCareType"
                :value="item.dictKey"
                :key="item.dictKey"
                >{{ item.dictValue }}</Option>
              </Select>
        </FormItem>
       <FormItem class="isNoWidth" v-if="formDataHealth.selfCare == 5">
             <span slot="label" class="validate">其他状况:</span>
              <Input type="textarea" placeholder="请输入其他状况" v-model.trim="formDataHealth.selfCareDesc"  />
        </FormItem>
       <FormItem class="isNoWidth">
             <span slot="label">详细健康状况:</span>
              <Input type="textarea" placeholder="请输入您的详细健康状况" v-model="formDataHealth.healthDesc"  />
        </FormItem>
     </Form>
  </div>
</template>

<script>
export default {
  props:{
    heathyCheck:{
      type:Boolean,
      default:()=>{
        return false
      }
    },
    formDataHealth:{
      type:Object,
      default:()=>{
        return {}
      }
    },
    userId:{
            type:Object,
            default:()=>{
                return ''
            }
        },
  },
  data(){
    return{
      isCheck:false,
      selfCareType:[//自理能力
        //  {dictKey:'1',dictValue:'完全自理'},
        //  {dictKey:'2',dictValue:'长期病患能自理'},
        //  {dictKey:'3',dictValue:'部份依赖他人'},
        //  {dictKey:'4',dictValue:'完全依赖他人'},
        //  {dictKey:'5',dictValue:'其他'}
      ],
      healthStatusType:[ //身体状况
        // {dictKey:'1',dictValue:'健康'},
        // {dictKey:'2',dictValue:'亚健康'},
        // {dictKey:'3',dictValue:'疾病'},
      ]
    }
  },
  created(){
    this.getDicType('SELF_CARE_ABILITY','selfCareType')
    this.getDicType('PHYSICAL_CONDITIONS','healthStatusType')
    this.getDetail()
    if(this.heathyCheck == true){
      this.isCheck = true
    }
  },
  methods:{ 
    changeCheck(val){
      this.isCheck = val
      this.$emit('changeHealth',val)
      // console.log(val);
    },
     getDetail(){
          if(this.userId && this.userId != ''){
            this.$get('/syaa/api/syuser/pc/residentArchive/selectUserHealth',{
              userId:this.userId,
              // familyId:this.familyId
            }).then(res=>{
              if(res.code == 200){
                  this.$emit('getHealthy',res.data)
              }else{
                this.$Message.error({
                  content:'健康需求评估信息获取失败',
                  background:true
                })
                return
              }
            })
          }
          
        },
         getDicType(type,model){
            this.$get('/voteapi/api/common/sys/findDictByDictType',{
                dictType:type
            }).then(res=>{
                this[model] = res.dataList
            })
        },
  },
   watch:{
     heathyCheck:{
       hanlder(val,newVal){
         if(val){
           this.isCheck = val
         }
       }
     }
   }
}
</script>

<style lang="less" scoped>
.form-div{
    width:100%;
    user-select: none;
    .isForm{
        display: flex;
        flex-wrap: wrap;
        .isWidth{
        width: 40%;
        margin-right: 40px;
        }
        .isNoWidth{
            width: 100%;
        }
    }
    
}
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}
/deep/.ivu-date-picker{
    display: inline;
}
</style>